$warm-bg: #fbf9f7;
$mid-bg: #f8f2ee;
$dark-red: #b21a1b;
$darker-red: #83171d;
$medium-grey: #787878;
$dark-grey: #211d1d;

.pane-clinical-services-groups {
  @import "wcm_cs_tabs";

  // Applies to both Category and Alpha
  .service-list {
    text-align: left;
    list-style-type: circle;

    li {
      font-size: 1.4rem;
      margin-top: 0;
      margin-bottom: 1.5rem;
      break-inside: avoid-column;
      padding-right: 1em;

      a {
        border-bottom: none;
        color: $dark-grey;

        &:hover {
          color: $wcm-bright-orange;
          border-bottom: 1px solid;
        }
      }
    }

    @include breakpoint($md) {
      column-count: 2;
    }
  }

  // Applies to Category Only
  .category-container {
    margin-bottom: 10rem;

    .category-row {
      @include breakpoint($md) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 5rem; // Add gap between items
        position: relative;
      }

      .services-container {
        display: flex;
        flex-flow: column;

        @include breakpoint($md) {
          flex: 0 1 calc(50% - 2.5rem); // Adjust width to ensure two items per row with gap
        }

        &.standard {
          position: relative;

          .inside-wrapper {
            @include breakpoint($md) {
              .service-list {
                display: none;
              }

              &:hover {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                right: 0;
                z-index: 1;

                .service-list {
                  display: block;
                }
              }
            }
          }
        }

        // For full width tile only
        &.fullwidth {
          .inside-wrapper {
            @include breakpoint($md) {
              .service-list {
                display: none;
              }

              &:hover {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                right: 0;
                z-index: 1;

                .service-list {
                  display: block;
                }
              }
            }
          }
        }
      }

      .inside-wrapper {
        background-color: $warm-bg;
        border: 1px solid $warm-bg;
        padding: 3rem 3rem 3rem 3rem;
        text-align: center;
        transition: all 0.4s ease-in-out;
        border-radius: 1rem;
        margin-bottom: 2rem;
        @include breakpoint($md) {
          margin-bottom: 5rem;
          min-height: 210px;
        }

        .category-icon {
          order: 1;
          fill: $wcm-red;
          color: $wcm-red;
          opacity: 1;
          margin-left: auto;
          margin-right: auto;
          width: 3.5rem;
          height: 3.5rem;
        }

        .category-name-description {
          order: 2;
          transform: translateY(0);
          transition: all 0.3s ease;
        }
        .category-name {
          font-family: $wcm-regular;
          color: $dark-grey;
          font-size: 2.1rem;
          text-align: center;
          margin-top: 1rem;
        }
        .category-description {
          font-family: $wcm-regular;
          color: $darker-red;
          font-size: 1.3rem;
          text-align: center;
        }

        .service-list {
          order: 4;
          display: none;
          margin-top: -4rem;

          @include breakpoint($md) {
            opacity: 0;
          }
        }

        .service-count {
          order: 3;
          flex-shrink: 1;
          color: $wcm-red;
          margin-bottom: 0;
          margin-top: 0.5rem;

          .count-wrapper {
            display: inline-block;
            background-color: $mid-bg;
            border-radius: 1.5rem;
            padding: 0.25rem 3rem;
          }
        }

        &:hover {
          cursor: pointer;
          background-color: $wcm-white;
          border: 1px solid $dark-red;
          box-shadow: 0 0 2.5rem 0 rgba(0, 0, 0, 0.1);

          .category-icon {
            opacity: 0;
          }

          .category-name-description {
            transform: translateY(-5.5rem);
          }

          .category-name {
            color: $dark-red;
          }

          .category-description {
            color: $medium-grey;
          }

          .service-list {
            order: 3;
            display: block;
            @include breakpoint($md) {
              opacity: 1;
            }
          }
          .service-count {
            order: 4;
            opacity: 0;
            display: none;
          }
        }

        // For standard tile only
        &.standard {
          .category-icon {
            transition: all 0.3s ease;
          }
        }
      }
    }
  }

  // Applies to Alpha Only
  .alpha-container {
    display: flex;
    flex-direction: column;
    @include breakpoint($md) {
    }

    .alpha-jump {
      margin-top: 2rem;

      @include breakpoint($md) {
        display: flex;
        flex-direction: row;
      }

      .go-to {
        color: $wcm-med-gray;
        display: block;
        text-align: center;
        @include breakpoint($md) {
          flex: 0 0 0%;
          flex-basis: initial;
          padding-right: 2rem;
        }
      }

      .letter-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        @include breakpoint($md) {
          flex: 1 0 0%;
          flex-wrap: nowrap;
          justify-content: space-around;
        }
        .letter {
          width: 11.1%;
          text-align: center;
          @include breakpoint($md) {
            width: auto;
          }
        }
      }
    }

    .services-wrapper {
      flex-grow: 1;
      margin-top: 2rem;

      .services-container {
        background-color: $warm-bg;
        border: 1px solid $warm-bg;
        padding: 1.5rem;
        border-radius: 1rem;
        margin-bottom: 2rem;
        .header-wrapper {
          .letter-header {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $wcm-red;
            background-color: $mid-bg;
            font-weight: bold;
            font-size: 2.2rem;
            width: 3.8rem;
            height: 3.8rem;
            border-radius: 50%;
            margin: 0;
          }
        }

        .service-list {
          margin-top: 2rem;
          margin-bottom: 0;
          padding-left: 3rem;

          @include breakpoint($md) {
            padding-left: 6rem;
          }
        }
      }
    }
  }
}
