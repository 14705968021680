.pane-bundle-wcm-clinical-service-group {
  .pane-title {
    color: $wcm-med-gray;
    margin-bottom: 1rem;
  }
  .fieldable-panels-pane {
    .field-content-items {
      @include breakpoint($sm) {
        columns: 2;
      }

      .field-content-item {
        border-bottom: 1px solid $wcm-bg-gray;
        margin-left: 1.5rem;
        margin-bottom: 0.5rem;
        padding-bottom: 1rem;
      }
    }
  }
}
