.wcm-cs-tabs {
  margin: 3rem 0;
  text-align: center;

  @include breakpoint($md) {
    text-align: left;
    margin-bottom: 5rem;
  }

  .tab-wrapper {
    display: inline-block;
    background-color: $wcm-bg-gray;
    border-radius: 2.5rem;
    padding: 0.4rem;
    button {
      color: $wcm-dark-gray;
      background-color: $wcm-bg-gray;
      border: none;
      border-radius: 2.25rem;
      padding: 0.5rem 2rem 0.5rem 2rem;
      font-size: 1.3rem;
      @include breakpoint($md) {
        padding: 0.5rem 4rem 0.75rem 4rem;
        font-size: 1.5rem;
      }
      &.active {
        color: $wcm-white;
        background-color: $wcm-red;
      }
      &:focus {
        outline: none;
      }
    }
  }
}
